import { Box, Button, Card, IconButton } from '@mui/material';
import DefaultCell from 'components/DefaultCell';
import CustomTable from 'components/customTable';
import '../../user_management/index.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import CrudPopUp from 'components/popUp/crudPopup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getUserListAccordingToType } from 'store/Thunk/userThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import AvatartWithText from 'components/AvatarWithText';
import ReadMore from 'components/ReadMore/ReadMore';
import { useNavigate } from 'react-router-dom';
import StatusPopup from 'components/popUp/StatusPopup';
import { updateUserStatus } from 'store/Thunk/parentThunk';
import { getLinkPartFromRole } from 'helper/functions';
import { deleteTeacher } from 'store/Thunk/teacherThunk';
import DeleteUserDialog from 'components/dialog/users/Delete/deleteUser';

type TeachingPreferenceType = {
  teacher: string;
  tutor: string;
};

export const TeachingPreference: TeachingPreferenceType = {
  teacher: 'Teach',
  tutor: 'Tutor',
};

const ClassItem = ({
  title,
  count,
  itemKey,
}: {
  title: string;
  count: number;
  itemKey: number;
}) => {
  return (
    <Box>
      {itemKey < 2 && (
        <Box
          sx={{
            background: '#F8F8F8',
            borderRadius: '4px',
            p: 1,
          }}
        >
          <span>{title}</span>
        </Box>
      )}
    </Box>
  );
};

function Teacher() {
  const navigate = useNavigate();
  const [activeButtonRow, setActiveButtonRow] = useState<any>(null);

  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPageSize] = useState(10);
  const [search, setSearch] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const { teacherList } = useAppSelector((state) => state.userReducer);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [status, setStatus] = useState('');
  const dispatch = useAppDispatch();
  const initial = getLinkPartFromRole();
  const [selectedUserToDelete, setSelectedUserToDelete] = useState({
    id: '',
    name: '',
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const defaultCellStyle = {};

  useEffect(() => {
    let schoolId = '';
    const intial = getLinkPartFromRole();
    if (intial === 'school-leader') {
      schoolId =
        localStorage.getItem('current_schoolId') ||
        localStorage.getItem('teacherSchool') ||
        '';
    }
    dispatch(getUserListAccordingToType({ userType: 'teacher', schoolId }));
  }, []);

  useEffect(() => {
    if (teacherList?.items?.length > 0) {
      setPageCount(teacherList?.meta?.totalPages);
      setTotalCount(teacherList?.meta?.totalCount);
    }
  }, [teacherList]);

  const handleEventClick = (rowIndex: number) => {
    setActiveButtonRow(rowIndex);
  };

  const handleDeleteUser = () => {
    setDeleteLoading(true);
    dispatch(deleteTeacher(selectedUserToDelete.id))
      .then(() => {
        fetchTableData();
      })
      .finally(() => {
        setOpenDeleteModal(false);
        setDeleteLoading(false);
        setSelectedUserToDelete({
          id: '',
          name: '',
        });
      });
  };

  const fetchTableData = () => {
    dispatch(
      getUserListAccordingToType({
        pageSize: perPage,
        pageIndex: pageNo,
        search,
        userType: 'teacher',
      }),
    );
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'teacherInfo',
      Cell: ({ value }: any) => <AvatartWithText value={value} />,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'School',
      accessor: 'school',
      Cell: ({ value }: any) => (
        <DefaultCell sx={defaultCellStyle} value={value} />
      ),
    },
    {
      Header: 'Classrooms',
      accessor: 'classroom',
      Cell: ({ value }: any) => (
        <Box className="class_list">
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {value?.map((item: any, index: number) => (
              <ClassItem itemKey={index} title={item} count={value?.length} />
            ))}
          </Box>
          <Box className="badge_head">
            {value.length > 2 && (
              <span style={{ marginBottom: '25px' }} className="count_badge">
                {'+' + (value.length - 2)}
              </span>
            )}
          </Box>
        </Box>
      ),
    },
    {
      Header: 'State',
      accessor: 'state',
      Cell: ({ value }: any) => (
        <ReadMore sx={{ minWidth: '50px' }} maxLength={50} text={value} />
      ),
    },
    {
      Header: 'Teaching Preference',
      accessor: 'teachingPreference',
      Cell: ({ row, value }: any) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'start' }}>
            <DefaultCell sx={defaultCellStyle} minWidth="100px" value={value} />
            <Box className="class_list">
              <span
                style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}
              >
                {row?.original?.topics?.map((item: any, index: number) => (
                  <ClassItem
                    itemKey={index}
                    title={item.name}
                    count={row?.length}
                  />
                ))}
              </span>
              <Box className="badge_head">
                {row?.original?.topics?.length > 2 && (
                  <span
                    style={{ marginBottom: '25px' }}
                    className="count_badge"
                  >
                    {'+' + (row?.original?.topics?.length - 2)}
                  </span>
                )}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row, value }: any) => (
        <Button
          variant="contained"
          className={value === 'active' ? 'green_bg_btn' : 'red_bg_btn'}
          onClick={() => {
            setStatus(value);
            setIsOpen(!isOpen);
            setSelectedUser(row?.original?.teacherId);
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {value?.charAt(0).toUpperCase() + value?.slice(1)}
        </Button>
      ),
    },
    {
      Header: 'Action',
      accessor: '',
      Cell: ({ row }: any) => (
        <>
          <IconButton onClick={() => handleEventClick(row.index)}>
            <MoreVertIcon />
          </IconButton>
          {activeButtonRow === row.index && (
            <CrudPopUp
              showEdit
              showDelete
              showView
              onDeleteClick={() => {
                setOpenDeleteModal(true);
                setSelectedUserToDelete({
                  id: row?.original?.teacherId,
                  name:
                    row?.original?.teacherInfo?.name ||
                    row?.original?.email ||
                    '',
                });
              }}
              onEditClick={() => {
                navigate(
                  `/${initial}/user-management/edit?mode=teacher&id=${row.original.teacherId}`,
                );
              }}
              onViewClick={() =>
                navigate(
                  `/${initial}/user-management/view?mode=teacher&id=${row.original.teacherId}`,
                )
              }
              setClose={() => setActiveButtonRow(null)}
            />
          )}
        </>
      ),
    },
  ];

  const fetchData = useCallback(({ pageSize, pageIndex, search }: any) => {
    setPageNo(pageIndex);
    setPageCount(pageSize);
    dispatch(
      getUserListAccordingToType({
        pageSize,
        pageIndex,
        search,
        userType: 'teacher',
      }),
    );
  }, []);

  const updateStatus = (updatedstatus: string) => {
    dispatch(
      updateUserStatus({ status: updatedstatus, uuid: selectedUser }),
    ).then(() => {
      fetchTableData();
      closeModal();
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedUser('');
  };

  const rows = useMemo(() => {
    if (teacherList?.items?.length > 0) {
      const data = teacherList?.items?.map((item: any) => ({
        teacherId: item.id,
        teacherInfo: {
          name: item?.name,
          image: item?.profileImage,
        },
        email: item?.email,
        topics: item?.topic?.map((item: any) => item.topic) || [],
        school: item?.school?.name,
        classroom: item?.classrooms?.map(
          (item: any) =>
            item?.classroom?.name.split(':')[1]?.trim() ||
            item?.classroom?.name,
        ),
        state: item?.school?.state,
        teachingPreference:
          'I ' +
          ((TeachingPreference[item.type as keyof TeachingPreferenceType] ||
            '') +
            (item?.grade?.length > 0
              ? ', ' + item?.grade[0]?.grade?.name
              : '')),

        status: item?.status || 'active',
      }));
      return data;
    } else return [];
  }, [teacherList]);

  return (
    <div style={{ position: 'relative' }}>
      <CustomTable
        isSorted={false}
        fetchData={fetchData}
        pageCount={pageCount}
        pageNo={pageNo}
        canSearch
        totalCount={totalCount}
        table={{
          columns: columns,
          rows: rows,
        }}
      />
      {isOpen && (
        <StatusPopup
          open={isOpen}
          status={status}
          onClose={closeModal}
          onSave={updateStatus}
        />
      )}
      <DeleteUserDialog
        loading={deleteLoading}
        open={openDeleteModal}
        title={`Are you sure you want to delete the user "${selectedUserToDelete?.name}" from G3MS?`}
        onOk={handleDeleteUser}
        onClose={() => {
          setOpenDeleteModal(false);
          setSelectedUserToDelete({
            id: '',
            name: '',
          });
        }}
      />
    </div>
  );
}

export default Teacher;
