import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Typography,
  Card,
  Autocomplete,
  TextField,
  Pagination,
  Button,
  InputAdornment,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
  Stack,
} from '@mui/material';
import DataTableHeadCell from 'components/customTable/DataTableHeadCell';
import DataTableBodyCell from 'components/customTable/DataTableBodyCell';
import FormControl from '@mui/material/FormControl';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from 'react-table';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from 'icons/search';
import { useAsyncDebounce } from 'react-table';
import { AnyClass } from '@casl/ability/dist/types/types';
import { Cancel } from '@mui/icons-material';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: number[];
      };
  hidePagination?: boolean;
  canSearch?: boolean;
  searchAssignment?: any;
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  title?: string;
  showPagination?: boolean;
  pagination?: {
    variant: 'contained' | 'gradient';
    color:
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | 'dark'
      | 'light';
  };
  isSorted?: boolean;
  noEndBorder?: boolean;
  searchText?: string;
  selectedItems?: any;
  showExportAllBtn?: boolean;
  showExportSelectedBtn?: boolean;
  makePaymentBtn?: boolean;
  paymentOpen?: any;
  fetchData?: any;
  pageCount?: any;
  totalCount?: any;
  pageNo?: any;
  manualSearch?: boolean;
  manualSort?: boolean;
  exportFields?: any;
  exportTable?: string;
  exportType?: string;
  exportSection?: string;
  additionalField?: any;
  onChangeCheckAllCheckbox?: any;
  isDisplayCheckAllCheckbox?: boolean;
  isCheckAll?: boolean;
  filterFor?: string;
  locationsIds?: any;
  defaultDisplayColumns?: any;
  changeTableColumns?: any;
  checkedColumns?: any;
  tableSetting?: string;
  clearCheckItem?: any;
  buttonTitle?: string;
  buttonTitleSecond?: string;
  backgroundColor?: string;
  color?: string;
  variant?: string;
  handleDeselect?: (e: any) => void;
  handleOnChange?: (e: any) => void;
  handleSecondAction?: (e: any) => void;
}

function DataTable({
  searchText,
  title,
  buttonTitle,
  buttonTitleSecond,
  handleOnChange,
  handleSecondAction,
  entriesPerPage,
  canSearch = true,
  table,
  fetchData,
  pageCount,
  totalCount,
  handleDeselect,
  searchAssignment,
  pageNo,
  defaultDisplayColumns,
  backgroundColor,
  color,
  variant,
  showPagination = true,
  hidePagination,
  isSorted = false,
}: Props): JSX.Element {
  let defaultValue: any;
  let entries: any[];

  if (entriesPerPage) {
    defaultValue = entriesPerPage.defaultValue
      ? entriesPerPage.defaultValue
      : '10';
    entries = entriesPerPage.entries
      ? entriesPerPage.entries
      : ['10', '25', '50', '100'];
  }

  const columns = useMemo<any>(
    () =>
      defaultDisplayColumns.length ? defaultDisplayColumns : table?.columns,
    [table],
  );
  const data = useMemo<any>(() => table.rows, [table]);

  // Search input value state
  const [search, setSearch] = useState('');

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNo || 0 },
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,

    state: { pageIndex, pageSize, globalFilter, sortBy },
  }: any = tableInstance;
  function clearTextField() {
    setSearch('');
    fetchData({
      pageIndex: 0,
      pageSize,
      search: '',
    });
  }
  function handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fetchData) {
        fetchData({
          pageIndex: 0,
          pageSize,
          search: searchAssignment ? searchAssignment : search,
        });
      }
    }
  }
  function handleCancel(e: any) {
    clearTextField();
  }
  const setEntriesPerPage = (value: any) => {
    fetchData({
      pageIndex: pageIndex,
      pageSize: value,
      search: searchAssignment ? searchAssignment : search,
    });
    setPageSize(value);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    gotoPage(value - 1);

    if (fetchData) {
      fetchData({
        pageIndex: value - 1,
        pageSize,
        search: searchAssignment ? searchAssignment : search,
      });
    }
  };

  const renderPagination = () => (
    <Pagination
      count={pageCount}
      page={pageIndex + 1}
      shape="rounded"
      onChange={handleChangePage}
    />
  );
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('grade');
  const onSearchChange = (value: any) => {
    setSearch(value); // Update the search state with the current input value.
  };
  // const state = {
  //   sortOptions: [{ id: 'Grade', desc: true },{ id: 'Title', desc: true }],
  //  }
  // const onSearchChange = useAsyncDebounce((value: any) => {

  // }, 50);
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'commodity',
      sort: 'asc',
    },
  ]);
  type Order = 'asc' | 'desc';
  const visibleRows = React.useMemo(
    () => [...page].sort(getComparator(order, orderBy)),

    [order, orderBy, page, pageSize],
  );
  //let visibleRows=page
  interface Data {
    grade: string;
    title: number;
    topic: string;
    subTopic: string;
    skill: string;
  }
  //  const visibleRows =  useEffect(()=>{

  //     [...page].sort(getComparator(order, orderBy))
  //     console.log("useeffect classdfasldkjfl;akdsjf s;aldf j")
  // },[order, orderBy, page, pageSize])
  function descendingComparator(a: any, b: any, orderBy1: any) {
    //   console.log("adsfadsfdsf",visibleRows)
    //  if( page){
    //   page?.map((row: any, key: any) => {
    //     prepareRow(row);
    //     console.log("row",row)
    //         {row.cells.map((cell: any) => (
    //           console.log("b[orderBy]b[orderBy]b[orderBy]b[orderBy]b[orderBy]",cell)
    //           // </Tooltip>
    //         ))}
    //   })
    //  }
    ///

    //  prepareRow(b);
    //  b?.cells?.forEach((element:any,index:any) => {
    //   console.log("b[orderBy]b[orderBy]b[orderBy]b[orderBy]b[orderBy]",element)
    //   console.log("dsfasdfasdfsafdasdfadsf",element.render('Cell'))
    //  });
    console.log('topic', orderBy);
    console.log('dsfasdfasdfsafdasdfadsf', b?.original[orderBy]);
    let sortB = '';
    let sortA = '';
    if (
      b?.original[orderBy] &&
      b?.original[orderBy][0] &&
      b?.original[orderBy][0][orderBy]
    ) {
      sortB = b?.original[orderBy][0][orderBy]?.name;
      console.log('upper b caliing');
    } else if (b?.original[orderBy]) {
      sortB = b?.original[orderBy];
      console.log('lower b caliing');
    }
    if (
      a?.original[orderBy] &&
      a?.original[orderBy][0] &&
      a?.original[orderBy][0][orderBy]
    ) {
      sortA = a?.original[orderBy][0][orderBy]?.name;
      console.log('upper a caliing');
    } else if (a?.original[orderBy]) {
      console.log('lower a caliing');
      sortA = a?.original[orderBy];
    }

    if (sortB < sortA) {
      return -1;
    }
    if (sortB > sortA) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof Data,
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
      console.log('headerGroups', visibleRows);
      console.log(event, property);
    };
  return (
    <Box>
      <Box ml={2} mt={2}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb="24px"
        >
          <Typography className="table_title">{title}</Typography>
          <Stack flexDirection={'row'}>
            {buttonTitle && title && (
              <Button
                variant="contained"
                className="primary_bg_btn"
                sx={{ ml: 3 }}
                onClick={handleOnChange}
                endIcon={<AddIcon />}
              >
                {buttonTitle}
              </Button>
            )}
          </Stack>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box sx={{ display: 'flex', gap: '10px', width: '100%' }}>
            {canSearch && (
              <TextField
                placeholder={searchText}
                sx={{
                  mb: 10,
                  width: { xs: '100%', sm: '40%', md: '100%' },
                  background: '#F9F9F9',
                }}
                size="small"
                value={search}
                onKeyDown={handleKeyDown}
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCancel}>
                        <Cancel />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          {buttonTitle && title === '' && (
            <Button
              variant="contained"
              className="primary_bg_btn"
              sx={{ ml: 3 }}
              onClick={handleOnChange}
              endIcon={<AddIcon />}
              style={{ minWidth: 'max-content' }}
            >
              {buttonTitle}
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer sx={{ mx: '0.5rem', width: '99%', borderRadius: '6px' }}>
        <Table
          {...getTableProps()}
          className={
            variant === 'borderLess' ? 'border-less-table' : 'custom-table'
          }
        >
          {isSorted ? (
            <Box component="thead">
              {headerGroups.map((headerGroup: any) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  className="listing-table-head"
                >
                  {headerGroup.headers.map((column: any, index: any) => (
                    <TableCell
                      key={column.id}
                      align="left"
                      sortDirection={orderBy === column.id ? order : false}
                    >
                      <TableSortLabel
                        sx={{
                          color: color ? color : '#52525B',
                          fontSize: '13px',
                          fontWeight: '500',
                          textTransform: 'capitalize',
                          '&.MuiTableSortLabel-root': {
                            opacity: 1,
                          },
                          '&.MuiTableSortLabel-node:hover': {
                            opacity: 0.8,
                          },
                          '&.Mui-active': {
                            opacity: 1,
                          },
                          '& .MuiTableSortLabel-icon': {
                            opacity: 0.4,
                          },
                        }}
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}
                        hideSortIcon={false}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </Box>
          ) : (
            <Box component="thead">
              {headerGroups.map((headerGroup: any) => (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  className="listing-table-head"
                >
                  {headerGroup.headers.map((column: any, index: any) => (
                    <DataTableHeadCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      width={column.width ? column.width : 'auto'}
                      align={column.align ? column.align : 'left'}
                      backgroundColor={backgroundColor || ''}
                      color={color || ''}
                    >
                      {column.render('Header')}
                    </DataTableHeadCell>
                  ))}
                </TableRow>
              ))}
            </Box>
          )}

          <TableBody {...getTableBodyProps()}>
            {visibleRows?.map((row: any, key: any) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} className="listing-table-cell">
                  {row.cells.map((cell: any) => (
                    // <Tooltip
                    //   key={cell.column.id} // Ensure each tooltip has a unique key
                    //   title={`${cell.column.Header}: ${cell.value}`} // Use column header and cell value as the tooltip title
                    //   arrow
                    // >
                    <>
                      {/* {JSON.stringify(cell.column)} */}

                      <DataTableBodyCell
                        variant={variant}
                        align={cell.column.align ? cell.column.align : 'left'}
                        width={cell.column.width ? cell.column.width : '150px'}
                        tooltip={{
                          key: cell?.column?.Header,
                          value: (cell?.value && cell?.value?.[0]) || '',
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </DataTableBodyCell>
                    </>
                    // </Tooltip>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && !hidePagination && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          my={4}
          sx={{ border: '1px solid #E9EBED', borderRadius: '6px', p: 2 }}
        >
          {entriesPerPage && (
            <Box flex="1" display="flex" alignItems="center">
              <Typography variant="body2" className="entries-page-txt">
                Show&nbsp;&nbsp;
              </Typography>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={['10', '25', '50', '100']}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: '5rem' }}
                renderInput={(params) => <TextField {...params} />}
              />
              <Typography variant="body2" className="entries-page-txt">
                &nbsp;&nbsp;entries of {totalCount}
              </Typography>
            </Box>
          )}
          {renderPagination()}
        </Box>
      )}
    </Box>
  );
}

DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ['5', '10', '15', '20', '25'] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  searchText: 'Search...',
  selectedItems: [],
  showExportAllBtn: false,
  makePaymentBtn: false,
  showExportSelectedBtn: false,
  paymentOpen: () => {},
  pageCount: null,
  pageNo: 0,
  totalCount: null,
  fetchData: null,
  manualSearch: false,
  manualSort: true,
  exportFields: [],
  exportTable: null,
  exportType: null,
  exportSection: null,
  additionalField: null,
  onChangeCheckAllCheckbox: () => {},
  isDisplayCheckAllCheckbox: false,
  isCheckAll: false,
  filterFor: '',
  locationsIds: null,
  defaultDisplayColumns: [],
  checkedColumns: [],
  changeTableColumns: () => {},
  tableSetting: '',
  clearCheckItem: () => {},
};

export default DataTable;
