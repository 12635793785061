// *** React Imports
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  InputLabel,
  List,
  FormControlLabel,
  Chip,
  Checkbox,
  Paper,
  Divider,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getDistrictByState,
  getDistrictList,
  getSchoolsInDistricts,
  getStates,
} from 'store/Thunk/districtThunk';

function SelectDistrictAndSchool({ state, setState, formError }: any) {
  const [selectAllSchool, setSelectAllSchool] = useState(false);
  const [selectAllSchoolNew, setSelectAllSchoolNew] = useState(false);
  const [selectAllDistricts, setSelectAllDistricts] = useState(false);
  const [selectAllDistrictsNew, setSelectAllDistrictsNew] = useState(false);
  const [selectAllStates, setSelectAllStates] = useState(false);
  const dispatch = useAppDispatch();

  const [loadingState, setLoadingState] = React.useState(false);
  const [loadingDistrict, setLoadingDistrict] = React.useState(false);
  const [loadingSchool, setLoadingSchool] = React.useState(false);
  const [openDistrict, setOpenDistrict] = React.useState(false);
  const [openState, setOpenState] = React.useState(false);
  const [openSchool, setOpenSchool] = React.useState(false);
  const [district, setDistrict] = React.useState<any[]>([]);
  const [school, setSchool] = React.useState<any[]>([]);

  const { districtList } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { districtState } = useAppSelector(
    (state: any) => state.districtReducer,
  );
  const { stateList } = useAppSelector((state: any) => state.districtReducer);
  const { schoolList } = useAppSelector((state: any) => state.schoolReducer);
  // const {schoolList}:any=[]

  const allStates = [
    ...new Set(district.map((district) => district.state)),
  ].sort();

  const statesArray = state?.state?.map((item: any) => item.name);
  console.log('district', stateList);
  console.log('district', state);
  console.log('district', districtList);
  console.log('district', districtState);
  console.log('district', statesArray);
  console.log('district', Array.isArray(state?.district) && openSchool);

  useEffect(() => {

    dispatch(
      getDistrictList({
        pageIndex: 0,
        pageSize: 7000,
      }),
    );
  }, []);

  useEffect(() => {
    if (openDistrict) {
      dispatch(
        getDistrictByState({
          pageIndex: 0,
          pageSize: 7000,
          states: statesArray,
        }),
      );
      setLoadingDistrict(openDistrict);
    }
  }, [openDistrict]);
  useEffect(() => {
    dispatch(getStates({ pageIndex: 0, pageSize: 7000 }));
    // setLoadingState(openState);
  }, []);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        if (Array.isArray(state?.district)) {
          setLoadingSchool(true);
          const districtIds = state?.district?.map((item: any) => item?.id);

          districtIds?.length > 0 &&
            dispatch(getSchoolsInDistricts(districtIds));
        } else {
          setSchool([]);
        }
      } catch (error) {
        console.error('Error fetching school data:', error);
      } finally {
        setLoadingSchool(false);
      }
    };

    fetchSchools();
  }, [state?.district, dispatch]);

  useEffect(() => {
    setLoadingDistrict(false);
    if (districtState?.items?.length > 0) {
      setDistrict([...districtState.items]);
    }
  }, [districtState]);

  useEffect(() => {
    if (schoolList?.length > 0) {
      setLoadingSchool(false);
      //m1   setSchool([...schoolList] );
    }
  }, [schoolList]);
  const handleToggleSelectAll = (name: string, checked: boolean) => {
    const updateFormData = (field: string, value: any) => {
      setState((prevFormData: any) => ({
        ...prevFormData,
        [field]: value,
      }));
    };

    if (name === 'school') {
      setSelectAllSchoolNew(checked);

      setSelectAllSchool((prev) => {
        const updatedList = !prev ? schoolList : [];
        updateFormData(name, updatedList);
        return !prev;
      });
    }
    if (name === 'state') {
      setSelectAllStates((selectAllStates) => !selectAllStates);

      checked
        ? setSelectAllSchool((prev) => {
          const updatedList = !prev ? allStates : [];
          updateFormData('state', updatedList);
          return !prev;
        })
        : setSelectAllSchool((prev) => {
          updateFormData('state', []); //  M2
          return !prev;
        });

      if (checked) {
        const updatedList = districtList?.items || [];
        updateFormData('district', updatedList);
      } else
        setSelectAllDistricts((prev) => {
          updateFormData('district', []); //M2
          return !prev;
        });
      checked
        ? setSelectAllSchool((prev) => {
          const updatedList = !prev ? schoolList : [];
          updateFormData('school', updatedList);
          return !prev;
        })
        : setSelectAllSchool((prev) => {
          updateFormData('school', []);  //M2
          return !prev;
        });
    }
    if (name === 'district') {
      setSelectAllDistrictsNew(checked);

      if (checked) {
        updateFormData(name, districtState);
      } else {
        updateFormData(name, []);
      }
    }
  };
  const handleToggleSelectAllNew = (name: string, checked: boolean,val:any) => {
    const updateFormData = (field: string, value: any) => {
      setState((prevFormData: any) => ({
        ...prevFormData,
        [field]: value,
      }));
    };

    if (name === 'school') {
      setSelectAllSchoolNew(checked);

      setSelectAllSchool((prev) => {
        const updatedList = !prev ? schoolList : [];
        updateFormData(name, updatedList);
        return !prev;
      });
    }
    if (name === 'state') {
      setSelectAllStates((selectAllStates) => !selectAllStates);

      checked
        ? setSelectAllSchool((prev) => {
          const updatedList = !prev ? allStates : [];
          updateFormData('state', updatedList);
          return !prev;
        })
        : setSelectAllSchool((prev) => {
          updateFormData('state', []); //  M2
          return !prev;
        });

      if (checked) {
        const updatedList = districtList?.items || [];
        updateFormData('district', updatedList);
      } else
        setSelectAllDistricts((prev) => {
          updateFormData('district', []); //M2
          return !prev;
        });
      checked
        ? setSelectAllSchool((prev) => {
          const updatedList = !prev ? schoolList : [];
          updateFormData('school', updatedList);
          return !prev;
        })
        : setSelectAllSchool((prev) => {
          updateFormData('school', []);  //M2
          handleChangeAutocompleteNew(name,val)
          return !prev;
        });

    }
    if (name === 'district') {
      setSelectAllDistrictsNew(checked);

      if (checked) {
        updateFormData(name, districtState);
      } else {
        updateFormData(name, []);
      }
    }
 
  };
  const handleChangeAutocompleteNew=
    (name: string,newValue:any)  => {
        if (selectAllSchool && newValue?.length !== schoolList?.length) {
          setSelectAllSchool(false);
        }
        console.log('handleChangeAutocomplete', newValue);
        console.log('handleChangeAutocomplete', name);
    

        if (
          selectAllDistricts &&
          newValue?.length !== districtState.items?.length
        ) {
          setSelectAllDistricts(false);
        }

        let param = {
          [name]: newValue,
          ...(name === 'topic' && {
            sub_topic: '',
            skill: '',
          }),
          ...(name === 'sub_topic' && {
            skill: '',
          }),
          ...(name === 'district' && {
            school: '',
          }),
        };
        setState({ ...state, ...param });
      };
  const handleChangeAutocomplete =
    (name: string) =>
      (
        event: React.SyntheticEvent<Element, Event>,
        newValue: string | null | any,
      ) => {
        if (selectAllSchool && newValue?.length !== schoolList?.length) {
          setSelectAllSchool(false);
        }
        console.log('handleChangeAutocomplete', newValue);
        console.log('handleChangeAutocomplete', name);
        if (name == "state" && newValue?.length > 0 && selectAllStates) {
          const updateFormData = (field: string, value: any) => {
            setState((prevFormData: any) => ({
              ...prevFormData,
              [field]: value,
            }));
          };
          // setSelectAllStates(false);
          // setSelectAllDistricts(false);
          // setSelectAllDistrictsNew(false);
          // setSelectAllSchool(false)
          // setSelectAllSchoolNew(false)
          // updateFormData('state', []);
          // updateFormData('district', []);
          // updateFormData('school', []);
          handleToggleSelectAllNew("state", false,newValue)
        }

        if (
          selectAllDistricts &&
          newValue?.length !== districtState.items?.length
        ) {
          setSelectAllDistricts(false);
        }

        let param = {
          [name]: newValue,
          ...(name === 'topic' && {
            sub_topic: '',
            skill: '',
          }),
          ...(name === 'sub_topic' && {
            skill: '',
          }),
          ...(name === 'district' && {
            school: '',
          }),
        };
        setState({ ...state, ...param });
      };

  return (
    <>
      <Grid item xs={4}>
        <InputLabel sx={{ color: '#484848', pb: 2 }}>State</InputLabel>

        <Autocomplete
          multiple
          id="tags-standard"
          open={openState}
          onOpen={() => setOpenState(true)}
          onClose={() => setOpenState(false)}
          getOptionLabel={(option) => option.name || option.id}
          options={stateList}
          loading={loadingState}
          value={state?.state || []}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          ListboxComponent={(props) => {
            return <List {...props}>{props.children}</List>;
          }}
          onChange={handleChangeAutocomplete('state')}
          PaperComponent={(paperProps) => {
            const { children, ...restPaperProps } = paperProps;
            return stateList?.length > 0 ? (
              <Paper {...restPaperProps}>
                <Box
                  onMouseDown={(e) => e.preventDefault()}
                  sx={{ display: 'flex', alignItems: 'center' }}
                  ml={4}
                  mr={4}
                  mt={4}
                >
                  <FormControlLabel
                    onClick={(e) => {
                      e.preventDefault();
                      const target = e.target as HTMLInputElement;
                      handleToggleSelectAll('state', target?.checked);
                    }}
                    label="Select all"
                    control={
                      <Checkbox
                        id="select-all-checkbox"
                        checked={selectAllStates}
                      />
                    }
                  />
                </Box>
                <Divider />
                {children}
              </Paper>
            ) : (
              <p></p>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectAllStates ? 'All States' : 'Select State'}
              error={formError && formError?.state}
              helperText={formError && formError?.state ? formError.state : ''}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingState ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>
      {!selectAllStates && (
        <Grid item xs={4}>
          <InputLabel sx={{ color: '#484848', pb: 2 }}>District ID</InputLabel>
          <Autocomplete
            multiple
            id="tags-standard"
            open={openDistrict}
            onOpen={() => setOpenDistrict(true)}
            onClose={() => setOpenDistrict(false)}
            getOptionLabel={(option) => option?.id || option.name}
            options={districtState || []}
            // loading={loadingDistrict}
            value={state?.district || []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ListboxComponent={(props) => {
              return <List {...props}>{props.children}</List>;
            }}
            renderTags={(value, getTagProps) => {
              const visibleTags = value.slice(0, 3);
              const remainingTagsCount = value.length - 3;
              return (
                <>
                  {visibleTags?.map((tag: any, index: any) => (
                    <Chip
                      style={{ textOverflow: 'ellipsis' }}
                      label={
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                            width: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {tag?.name}
                        </p>
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {remainingTagsCount > 0 && (
                    <Chip label={`+${remainingTagsCount}`} color="primary" />
                  )}
                </>
              );
            }}
            onChange={handleChangeAutocomplete('district')}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return districtState?.length > 0 ? (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    ml={4}
                    mr={4}
                    mt={4}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        const target = e.target as HTMLInputElement;
                        handleToggleSelectAll('district', target.checked);
                      }}
                      label="Select all"
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={selectAllDistrictsNew}
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              ) : (
                <p></p>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select District"
                error={formError && formError?.district}
                helperText={
                  formError && formError?.district ? formError.district : ''
                }
              // InputProps={{
              //   ...params.InputProps,
              //   endAdornment: (
              //     <React.Fragment>
              //       {loadingDistrict ? (
              //         <CircularProgress color="inherit" size={20} />
              //       ) : null}
              //       {params.InputProps.endAdornment}
              //     </React.Fragment>
              //   ),
              // }}
              />
            )}
          />
        </Grid>
      )}
      {!selectAllStates && !selectAllDistrictsNew  && (
        <Grid item xs={4}>
          <InputLabel sx={{ color: '#484848', pb: 2 }}>School ID</InputLabel>
          <Autocomplete
            multiple
            id="tags-standard"
            open={openSchool}
            loading={loadingSchool}
            onOpen={() => setOpenSchool(true)}
            onClose={() => setOpenSchool(false)}
            options={school}
            renderTags={(value, getTagProps) => {
              const visibleTags = value.slice(0, 3);
              const remainingTagsCount = value.length - 3;
              return (
                <>
                  {visibleTags?.map((tag: any, index: any) => (
                    <Chip
                      style={{ textOverflow: 'ellipsis' }}
                      label={
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                            width: '120px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {tag?.name}
                        </p>
                      }
                      {...getTagProps({ index })}
                    />
                  ))}
                  {remainingTagsCount > 0 && (
                    <Chip label={`+${remainingTagsCount}`} color="primary" />
                  )}
                </>
              );
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            ListboxComponent={(props) => {
              return <List {...props}>{props.children}</List>;
            }}
            value={state?.district?.length > 0 ? state?.school : []}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return schoolList.length > 0 ? (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    ml={4}
                    mr={4}
                    mt={4}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        const target = e.target as HTMLInputElement;
                        handleToggleSelectAll('school', target.checked);
                      }}
                      label="Select all"
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={selectAllSchool}
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              ) : (
                <p></p>
              );
            }}
            onChange={handleChangeAutocomplete('school')}
            getOptionLabel={(option: any) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select school"
                error={formError && formError?.school}
                helperText={
                  formError && formError?.school ? formError.school : ''
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingSchool ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      ) }
    </>
  );
}

export default SelectDistrictAndSchool;
